@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes pulse {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
}

.skeleton-loader {
	@apply rounded bg-gray-300;
	animation: pulse 1.5s ease-in-out infinite;
}

/* slider background */
.volume-slider {
	@apply bg-gray-200 relative appearance-none w-full h-2 rounded cursor-pointer;
	/* z-index: -2; */
}

/* slider background up to the thumb */
.volume-slider::before {
	content: "";
	@apply bg-sky absolute left-0 h-2 rounded;
	width: var(--volume-level);
}

/* slider thumb */
.volume-slider::-webkit-slider-thumb {
	@apply bg-transparent appearance-none h-4 w-4 rounded-full cursor-pointer;
}
.volume-slider::-moz-range-thumb {
	@apply bg-transparent appearance-none h-4 w-4 rounded-full cursor-pointer;
}
.volume-slider::-ms-thumb {
	@apply bg-transparent appearance-none h-4 w-4 rounded-full cursor-pointer;
}

.react-player {
	@apply bg-gradient-to-r from-navy-700 to-navy-500 rounded-t-lg;
}

@keyframes borderPulse {
	0% {
		border-color: rgba(255, 255, 255, 0.3);
	}
	50% {
		border-color: rgba(255, 255, 255, 0.8);
	}
	100% {
		border-color: rgba(255, 255, 255, 0.3);
	}
}

.animate-border {
	animation: borderPulse 2s infinite;
}